import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { Footer } from "./components/Footer";
import { useState } from "react";
import { useEffect } from "react";

function App() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="App">
      {isLoading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <HomePage
                setIsLoading={(loading) => {
                  setIsLoading(loading);
                }}
              />
            }
          />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
