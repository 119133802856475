import VideoRotate from "../../components/VideoRotate";
import "./Page04.css";

const sensorFeaturesLeft = [
  {
    title: "Unrivaled Versatility",
    text: "From field to greenhouses, garden to stadium; our sensor is designed for seamless use anywhere, anytime.",
  },
  {
    title: "Precision Personified",
    text: "Mikroterra's soil sensor meticulously measures soil moisture, temperature, and EC values. Every detail, now at your fingertips.",
  },
  {
    title: "Resilient Design",
    text: "Our sensor's rugged design and IP68 certification ensure it withstands the elements. Dependability reimagined for the modern farmer.",
  },
];

const sensorFeaturesRight = [
  {
    title: "Enduring Performance",
    text: "Our sensor defies time with a robust 10-year battery life, IP68 dust and water resistance, and no maintenance requirement. A decade of discovery, uncompromised.",
  },
  {
    title: "Deep-Dive Technology",
    text: "With the capacity to be fully embedded up to 40 cm deep, Mikroterra's sensor uncovers what's beneath, bringing the unseen to light.",
  },
  {
    title: "Effortless Setup",
    text: "Mikroterra’s sensor promises an installation as seamless as its use. Scan the QR code, set it up, and let the transformation begin.",
  },
];

const SensorFeature = ({ title, text, extraTitleStyle }) => {
  return (
    <div className="sensorfeature__container">
      <div className="sensorfeature__title" style={extraTitleStyle}>
        {title}
      </div>
      <div className="sensorfeature__text">{text}</div>
    </div>
  );
};

export const Page04 = () => {
  return (
    <div className="page04__container" id="product">
      <div className="page04__title-container">
        <p className="page04__title">Deeper Look</p>
      </div>
      <div className="page04__content">
        <div className="page04__left">
          <div className="page04__blur01"></div>
          {sensorFeaturesLeft.map((feature, index) => (
            <SensorFeature
              key={index}
              title={feature.title}
              text={feature.text}
              extraTitleStyle={{
                alignSelf: window.innerWidth < 768 ? "flex-start" : "flex-end",
              }}
            />
          ))}
        </div>
        <div className="page04__video">
          <VideoRotate />
        </div>
        <div className="page04__right">
          <div className="page04__blur02"></div>
          {sensorFeaturesRight.map((feature, index) => (
            <SensorFeature
              key={index}
              title={feature.title}
              text={feature.text}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
