import { Header } from "../../components/Header";
import "./Page01.css";
import svg01 from "../../assets/svg01.svg";

export const Page01 = ({ isMenuExpanded, setIsMenuExpanded }) => {
  return (
    <div className="page01__container" id="home">
      <div className="page01__blur01"></div>
      <div className="page01__blur02"></div>
      <img
        src={svg01}
        alt="svg01"
        className="page01__svg"
        style={{ filter: isMenuExpanded ? "blur(5px)" : "blur(0px)" }}
      />
      <Header
        isMenuExpanded={isMenuExpanded}
        setIsMenuExpanded={setIsMenuExpanded}
      />
      <div
        className="page01__content"
        style={{ filter: isMenuExpanded ? "blur(5px)" : "blur(0px)" }}
      >
        <div className="page01__title-container">
          <p className="page01__sentence">
            Innovate the soil, elevate the yield!
          </p>
          <p className="page01__title">MiKROTERRA</p>
        </div>
        <div className="page01__image-container">
          <img
            src="https://mktrcdn.s3.amazonaws.com/product01.webp"
            alt="product01"
            className="page01__image"
          />
        </div>
      </div>
    </div>
  );
};
