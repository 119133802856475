import { useEffect, useRef } from "react";
import "./Header.css";
import logo01 from "../assets/logos/logo01.png";
import logo02 from "../assets/logos/logo02.png";

const socialLinks = [
  {
    href: "https://www.instagram.com/",
    iconClass: "fa-brands fa-instagram",
  },
  {
    href: "https://twitter.com/",
    iconClass: "fa-brands fa-x-twitter",
  },
  {
    href: "https://www.linkedin.com/",
    iconClass: "fa-brands fa-linkedin",
  },
];

export const Header = ({ isMenuExpanded, setIsMenuExpanded }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsMenuExpanded]);

  return (
    <div className={"header__container"}>
      {!isMenuExpanded ? (
        <>
          <div className="header__logo-container">
            <img src={logo01} alt="logo01" className="header__logo" />
          </div>
          <div className="header__right-container">
            <div className="header__menu-container">
              <svg
                onClick={() => setIsMenuExpanded(!isMenuExpanded)}
                className="header__menu-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="clamp(24px, 1rem + 0.75vw, 32px)"
                height="23"
                viewBox="0 0 33 23"
                fill="none"
              >
                <path d="M32.7402 0H0V2.29893H32.7402V0Z" fill="white" />
                <path
                  d="M32.7402 10.3369H0V12.6358H32.7402V10.3369Z"
                  fill="white"
                />
                <path
                  d="M32.7402 20.6748H0V22.9737H32.7402V20.6748Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="header__launchos-container">
              <svg
                className="header__launchos-blur"
                xmlns="http://www.w3.org/2000/svg"
                width="162"
                height="96"
                viewBox="0 0 162 96"
                fill="none"
              >
                <g filter="url(#filter0_f_42_10)">
                  <ellipse cx="81" cy="48" rx="61" ry="28" fill="#174A45" />
                </g>
                <defs>
                  <filter
                    id="filter0_f_42_10"
                    x="0"
                    y="0"
                    width="162"
                    height="96"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="10"
                      result="effect1_foregroundBlur_42_10"
                    />
                  </filter>
                </defs>
              </svg>
              <button className="header__launchos-button">Launch OS</button>
            </div>
          </div>
        </>
      ) : (
        <div className="header__container-expanded">
          <div className="header__menu-logo-container">
            <img src={logo02} alt="logo02" className="header__menu-logo" />
          </div>
          <div className="header__menu-list" ref={menuRef}>
            <a
              href="#home"
              className="header__menu-item"
              onClick={() => setIsMenuExpanded(!isMenuExpanded)}
            >
              Home
            </a>
            <a
              href="#product"
              className="header__menu-item"
              onClick={() => setIsMenuExpanded(!isMenuExpanded)}
            >
              Product
            </a>
            <a
              href="#metrics"
              className="header__menu-item"
              onClick={() => setIsMenuExpanded(!isMenuExpanded)}
            >
              Metrics
            </a>
            <a
              href="#mikroterraos"
              className="header__menu-item"
              onClick={() => setIsMenuExpanded(!isMenuExpanded)}
            >
              Mikroterra OS
            </a>
            <a
              href="#contact"
              className="header__menu-item"
              onClick={() => setIsMenuExpanded(!isMenuExpanded)}
            >
              Contact
            </a>
          </div>
          <svg
            className="header__close-icon"
            onClick={() => setIsMenuExpanded(!isMenuExpanded)}
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
          >
            <path
              d="M0.000240326 32.2537L2.28094 34.5342L17.2672 19.5479L32.2535 34.5342L34.5342 32.2537L19.5478 17.2673L34.5342 2.28094L32.2535 0.000442505L17.2672 14.9867L2.28094 0.000442505L0.000240326 2.28094L14.9866 17.2673L0.000240326 32.2537Z"
              fill="white"
            />
          </svg>
          {/* <div className="header__middle-socials">
            {socialLinks.map((link, index) => (
              <a key={index} href={link.href} target="_blank" rel="noreferrer">
                <i
                  className={link.iconClass}
                  style={{
                    fontSize: "clamp(24px, 1rem + 0.75vw, 32px)",
                    color: "#fff",
                  }}
                />
              </a>
            ))}
          </div> */}
        </div>
      )}
    </div>
  );
};
