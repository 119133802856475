import "./Page05.css";
import ec from "../../assets/metric_images/ec.png";
import temp from "../../assets/metric_images/temp.png";
import moisture from "../../assets/metric_images/moisture.png";

const MetricCard = ({ image, title, text }) => {
  return (
    <div className="page05__metric-container">
      <div className="page05__metric-image-container">
        <img className="page05__metric-image" src={image} alt="metric" />
      </div>
      <div className="page05__metric-title-container">
        <p className="page05__metric-title">{title}</p>
      </div>
      <div className="page05__metric-text-container">
        <p className="page05__metric-text">{text}</p>
      </div>
    </div>
  );
};

const metricsData = [
  {
    image: ec,
    title: "Electrical Conductivity",
    text: "Mikroterra’s sensor measures soil EC, a key indicator of nutrient availability and salinity levels in your soil. This essential metric allows you to optimize your fertilizer usage and effectively monitor salinity to prevent potential harm to your plants. With this information at your disposal, you can ensure maximum productivity while maintaining the health of your soil, paving the way for sustainable farming practices.",
  },
  {
    image: moisture,
    title: "Soil Moisture",
    text: "Mikroterra’s sensor measures soil moisture, a critical factor in plant health and yield. This key metric enables you to manage your irrigation precisely and avoid both under and overwatering, which can lead to root diseases and reduced crop quality. Armed with this data, you can improve water use efficiency and ensure the healthy growth of your crops or the lushness of your sport field’s turf, contributing to sustainable and responsible water management practices.",
  },
  {
    image: temp,
    title: "Soil Temperature",
    text: "Mikroterra’s sensor also captures soil temperature, a vital determinant of seed germination, root growth, and nutrient absorption. Monitoring this crucial metric helps you decide the optimal timing for planting and aids in predicting plant growth stages. With this knowledge at your fingertips, you can enhance crop productivity and predict yield performance, ultimately driving sustainable and efficient farming practices.",
  },
];

export const Page05 = () => {
  return (
    <div className="page05__container" id="metrics">
      <div className="page05__title-container">
        <p className="page05__title">Metrics</p>
      </div>
      <div className="page05__content">
        <div className="page05__blur01"></div>
        {metricsData.map((metric, index) => (
          <MetricCard key={index} {...metric} />
        ))}
      </div>
    </div>
  );
};
