import { useEffect, useRef } from "react";

const VideoRotate = () => {
  const videoRef = useRef(null);
  const heroRef = useRef(null);

  useEffect(() => {
    const myVideo = videoRef.current;

    function updateVideoTime() {
      const heroHeight = heroRef.current.offsetHeight;
      const rect = heroRef.current.getBoundingClientRect();

      const playPercent = Math.min(
        100,
        Math.max(
          0,
          ((-rect.top + window.innerHeight / 3) /
            (heroHeight - window.innerHeight)) *
            100
        )
      );

      // myVideo.style.opacity =
      //   playPercent < 10 ? playPercent / 10 : 1 - (playPercent - 80) / 20;

      myVideo.style.opacity = playPercent < 10 ? playPercent / 10 : 1;

      const videoLength = myVideo.duration;

      const _currentTime = +((playPercent * videoLength) / 100).toFixed(4);

      if (_currentTime !== myVideo.currentTime) {
        myVideo.currentTime = _currentTime;
      }

      window.requestAnimationFrame(updateVideoTime);
    }

    const handleScroll = () => window.requestAnimationFrame(updateVideoTime);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="hero-section" ref={heroRef} style={{ height: "200vh" }}>
      <video
        playsInline
        muted
        preload="auto"
        id="myVideo"
        ref={videoRef}
        style={{ height: "100vh", width: "100%", position: "sticky", top: 0 }}
      >
        <source
          src="https://mktrcdn.s3.amazonaws.com/sensor.webm"
          type="video/webm"
        />
      </video>
    </div>
  );
};

export default VideoRotate;
