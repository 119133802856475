import "./Page09.css";
import logo02 from "../../assets/logos/logo02.png";

export const Page09 = () => {
  return (
    <div className="page09__container">
      <div className="page09__content">
        <div className="page09__blur01"></div>
        <div className="page09__product-container">
          <img
            className="page09__product"
            src="https://mktrcdn.s3.amazonaws.com/product03.webp"
            alt="product03"
          />
        </div>
        <div className="page09__sentence">Bringing the unseen to light...</div>
        <div className="page09__logo-container">
          <img className="page09__logo" src={logo02} alt="logo02" />
        </div>
      </div>
    </div>
  );
};
