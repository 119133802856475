import "./HomePage.css";
import { useEffect, useState } from "react";
import { Page01 } from "./HomePages/Page01";
import { Page02 } from "./HomePages/Page02";
import { Page03 } from "./HomePages/Page03";
import { Page04 } from "./HomePages/Page04";
import { Page05 } from "./HomePages/Page05";
import { Page06 } from "./HomePages/Page06";
import { Page07 } from "./HomePages/Page07";
import { Page08 } from "./HomePages/Page08";
import { Page09 } from "./HomePages/Page09";
import { FloatingButton } from "../components/FloatingButton";

export const HomePage = ({ setIsLoading }) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [isFloatingButtonShown, setIsFloatingButtonShown] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isMenuExpanded ? "hidden" : "unset";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isMenuExpanded]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsFloatingButtonShown(true);
      } else {
        setIsFloatingButtonShown(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="home__container">
      <FloatingButton
        isMenuExpanded={isMenuExpanded}
        isFloatingButtonShown={isFloatingButtonShown}
        setIsLoading={setIsLoading}
      />
      <Page01
        isMenuExpanded={isMenuExpanded}
        setIsMenuExpanded={setIsMenuExpanded}
        setIsLoading={setIsLoading}
      />
      <Page02 setIsLoading={setIsLoading} />
      <Page03 setIsLoading={setIsLoading} />
      <Page04 setIsLoading={setIsLoading} />
      <Page05 setIsLoading={setIsLoading} />
      <Page06 setIsLoading={setIsLoading} />
      <Page07 setIsLoading={setIsLoading} />
      <Page08 setIsLoading={setIsLoading} />
      <Page09 setIsLoading={setIsLoading} />
    </div>
  );
};
