import { useState, useRef } from "react";
import "./Page08.css";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

export const Page08 = () => {
  const recaptchaRef = useRef(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [recaptchaCheck, setRecaptchaCheck] = useState(false);

  const handleNameChange = (event) => {
    const result = event.target.value.replace(/[^a-zçğıöşü\s]/gi, "");
    setName(result);
  };

  const handleEmailChange = (event) => {
    const result = event.target.value;
    setEmail(result);
  };

  const handleEmailKeyPress = (event) => {
    const charCode = event.charCode;
    const char = String.fromCharCode(charCode);

    if (
      (char >= "a" && char <= "z") ||
      (char >= "A" && char <= "Z") ||
      (char >= "0" && char <= "9") ||
      char === "." ||
      (char === "@" && event.target.value.indexOf("@") === -1)
    ) {
      // İngilizce harf, rakam, "." veya ilk "@" sembolü kabul ediliyor
      return;
    } else {
      // Diğer karakterler engelleniyor
      event.preventDefault();
    }
  };

  const handlePhoneChange = (event) => {
    const result = event.target.value.replace(/[^0-9+]/g, "");
    setPhone(result);
  };

  const handlePhoneKeyPress = (event) => {
    const charCode = event.charCode;
    const char = String.fromCharCode(charCode);

    if (/[0-9+]/.test(char)) {
      // Rakam veya "+" sembolü kabul ediliyor
      return;
    } else {
      // Diğer karakterler engelleniyor
      event.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    const recaptchaValue = recaptchaRef.current.getValue();

    if (
      name.length > 0 &&
      phone.length > 0 &&
      email.length > 0 &&
      recaptchaCheck
    ) {
      axios
        .post("https://server11.mikroterratechnologies.com:3015/send-email", {
          name: name,
          phone: phone,
          email: email,
          reCaptchaData: recaptchaValue,
        })
        .then((response) => {
          console.log("Your message has been sent successfully");
          setName("");
          setPhone("");
          setEmail("");
          setRecaptchaCheck(false);
        })
        .catch((error) => {
          console.log("An error occurred while sending the message");
        });
    }

    e.preventDefault();
    recaptchaRef.current.reset();

    return;
  };

  const isFormFilled =
    name.length > 0 && phone.length > 0 && email.length > 0 && recaptchaCheck;

  return (
    <div className="page08__container" id="contact">
      <div className="page08__title-container">
        <p className="page08__title">Get Started</p>
      </div>
      <div className="page08__content">
        <div className="page08__blur01"></div>
        <div className="page08__sentence">
          Join the farming revolution with Mikroterra. Greater yields, less
          waste. Contact us for purchases or inquiries today.
        </div>
        <form className="page08__form" onSubmit={handleSubmit}>
          <input
            id="name"
            name="name"
            value={name}
            type="text"
            placeholder="Name"
            className="page08__form-input"
            onChange={handleNameChange}
          />
          <input
            id="email"
            name="email"
            value={email}
            type="email"
            placeholder="E-Mail"
            className="page08__form-input"
            onChange={handleEmailChange}
            onKeyPress={handleEmailKeyPress}
          />
          <input
            id="tel"
            name="tel"
            value={phone}
            type="tel"
            placeholder="Phone Number"
            className="page08__form-input"
            onChange={handlePhoneChange}
            onKeyPress={handlePhoneKeyPress}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LeY6hkpAAAAAC9sYZuz688zfJu9unzRmCjyvFQr"
            onChange={() => setRecaptchaCheck(true)}
          />
          <button
            disabled={!isFormFilled}
            style={{
              border: isFormFilled ? "2px solid #4d8a84" : "none",
              backgroundColor: !isFormFilled && "#6E6E6E",
              cursor: isFormFilled ? "pointer" : "not-allowed",
            }}
            className="page08__form-button"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};
