import "./Page02.css";

export const Page02 = () => {
  return (
    <div className="page02__container">
      <div className="page02__content">
        <div className="page02__blur01"></div>
        <div className="page02__text-container">
          <p className="page02__text">
            Discover Mikroterra; a seamless blend of agriculture, earth, and
            technology. Our innovative system, designed for professional
            farmers, puts comprehensive farming control in your hands.
          </p>
        </div>
        <div className="page02__image-container">
          <img
            src="https://mktrcdn.s3.amazonaws.com/product02.webp"
            alt="product02"
            className="page02__image"
          />
        </div>
      </div>
    </div>
  );
};
