import arrow from "../assets/arrow.png";

export const FloatingButton = ({ isMenuExpanded, isFloatingButtonShown }) => {
  return (
    <button
      style={{
        display: isMenuExpanded || !isFloatingButtonShown ? "none" : "block",
      }}
      className="floating-button"
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <img src={arrow} style={{ height: "100%", width: "100%" }} alt="arrow" />
    </button>
  );
};
