import "./Page07.css";
import logo_os01 from "../../assets/logos/logo_os01.png";

export const Page07 = () => {
  return (
    <div className="page07__container" id="mikroterraos">
      <p className="page07__text">Introducing</p>
      <p className="page07__title">Mikroterra OS</p>
      <div className="page07__logo-container">
        <div className="page07__blur01"></div>
        <img src={logo_os01} alt="" className="page07__logo" />
      </div>
      <p className="page07__text">
        Your digital companion for sustainable agriculture
      </p>
      <div className="page07__image-container">
        <img
          src="https://mktrcdn.s3.amazonaws.com/device01.webp"
          alt=""
          className="page07__image"
        />
      </div>
      <p className="page07__small-text">
        This robust platform empowers you to create comprehensive agriculture
        projects with precision. Benefit from a seamless overview of your
        cultivation journey. Ensure you never miss crucial farming milestones.
        On both your PC and mobile phone, Mikroterra OS brings your soil sensor
        data to life, transforming raw data into meaningful, comparative
        graphics you can utilize to optimize your agricultural operations.
      </p>
    </div>
  );
};
