import { useState, useRef } from "react";
import "./Footer.css";
import logo03 from "../assets/logos/logo03.png";
import arrowRight from "../assets/arrowRight.png";

const socialLinks = [
  {
    href: "https://www.instagram.com/",
    iconClass: "fa-brands fa-instagram",
  },
  {
    href: "https://twitter.com/",
    iconClass: "fa-brands fa-x-twitter",
  },
  {
    href: "https://www.linkedin.com/",
    iconClass: "fa-brands fa-linkedin",
  },
];

export const Footer = () => {
  const [eposta, setEposta] = useState("");
  const emailRef = useRef(null);

  const handleEmailChange = (event) => {
    const result = event.target.value;
    setEposta(result);
  };

  const handleEmailKeyPress = (event) => {
    const charCode = event.charCode;
    const char = String.fromCharCode(charCode);

    if (
      (char >= "a" && char <= "z") ||
      (char >= "A" && char <= "Z") ||
      (char >= "0" && char <= "9") ||
      char === "." ||
      (char === "@" && event.target.value.indexOf("@") === -1)
    ) {
      // İngilizce harf, rakam, "." veya ilk "@" sembolü kabul ediliyor
      return;
    } else {
      // Diğer karakterler engelleniyor
      event.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const email = "info@mikroterra.com";
    const subject = "Mikroterra Newsletter Subscription";
    const body = `Please add me to your newsletter!\n\nEmail: ${eposta}\n\n`;

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    emailRef.current.href = mailtoLink;
    emailRef.current.click();
  };

  return (
    <div className="footer__container">
      <div className="footer__blur01"></div>
      <div className="footer__content">
        <div className="footer__left">
          <div className="footer__logo-container">
            <img src={logo03} alt="logo" className="footer__logo" />
          </div>
          <p className="footer__copyright">
            Copyright<sup>©</sup> Mikroterra. All Rights Reserved
          </p>
        </div>
        <div className="footer__middle">
          <div className="footer__middle-top">
            <p className="footer__middle-top-text">User Agreement</p>
            <p className="footer__middle-top-text">Privacy Policy</p>
            <p className="footer__middle-top-text">Cookie Policy</p>
          </div>
          {/* <form className="footer__subscribe-form" onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              required
              placeholder="Subscribe with your e-mail for updates and offers"
              className="footer__middle-subscribe"
              onChange={handleEmailChange}
              onKeyPress={handleEmailKeyPress}
            />
            <button type="submit" className="footer__email-button">
              <img
                src={arrowRight}
                alt="footer__email-arrow"
                className="footer__email-arrow"
              />
            </button>
            <a ref={emailRef} style={{ display: "none" }} href="" />
          </form> */}

          {/* <div className="footer__middle-socials">
            {socialLinks.map((link, index) => (
              <a key={index} href={link.href} target="_blank" rel="noreferrer">
                <i
                  className={link.iconClass}
                  style={{
                    fontSize: "clamp(24px, 1rem + 0.75vw, 32px)",
                    color: "#fff",
                  }}
                />
              </a>
            ))}
          </div> */}
        </div>
        <div className="footer__right">
          <p className="footer__right-text">Mikroterra Inc.</p>
          <p className="footer__right-text">Bursa, Türkiye</p>
          <p className="footer__right-text">info@mikroterra.com</p>
          {/* <p className="footer__right-text">+90 224 442 83 43</p> */}
        </div>
      </div>
    </div>
  );
};
