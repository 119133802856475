import { useState } from "react";
import "./Page06.css";
import x from "../../assets/x.svg";
import moisture from "../../assets/metric_images/moisture.png";
import insight01 from "../../assets/metric_images/insight01.png";
import ec from "../../assets/metric_images/ec.png";
import insight02 from "../../assets/metric_images/insight02.png";
import insight03 from "../../assets/metric_images/insight03.png";

const insightsData = [
  {
    id: 1,
    image: moisture,
    title: "Optimized Irrigation",
    text: "Mikroterra’s sensor measures soil EC, a key indicator of nutrient availability and salinity levels in your soil. This essential metric allows you to optimize your fertilizer usage and effectively monitor salinity to prevent potential harm to your plants. With this information at your disposal, you can ensure maximum productivity while maintaining the health of your soil, paving the way for sustainable farming practices.",
  },
  {
    id: 2,
    image: insight01,
    title: "Improved Growth",
    text: "Mikroterra’s sensor measures soil moisture, a critical factor in plant health and yield. This key metric enables you to manage your irrigation precisely and avoid both under and overwatering, which can lead to root diseases and reduced crop quality. Armed with this data, you can improve water use efficiency and ensure the healthy growth of your crops or the lushness of your sport field’s turf, contributing to sustainable and responsible water management practices.",
  },
  {
    id: 3,
    image: ec,
    title: "Efficient Fertilizer Use",
    text: "All these accurate soil measurements provide early warning signs of potential issues like nutrient deficiencies, temperature stress, or waterlogging. Farmers can take preventive actions to ensure the health of their crops, avoiding loss of yield or even total crop failure.",
  },
  {
    id: 4,
    image: insight02,
    title: "Data-Driven Decisions",
    text: "Mikroterra’s sensor measures soil moisture, a critical factor in plant health and yield. This key metric enables you to manage your irrigation precisely and avoid both under and overwatering, which can lead to root diseases and reduced crop quality. Armed with this data, you can improve water use efficiency and ensure the healthy growth of your crops or the lushness of your sport field’s turf, contributing to sustainable and responsible water management practices.",
  },
  {
    id: 5,
    image: insight03,
    title: "Preventive Actions",
    text: "All these accurate soil measurements provide early warning signs of potential issues like nutrient deficiencies, temperature stress, or waterlogging. Farmers can take preventive actions to ensure the health of their crops, avoiding loss of yield or even total crop failure.",
  },
];

const InsightCard = ({ image, title, text, expanded, onToggle }) => {
  return (
    <div className={`page06__insight-container ${expanded ? "expanded" : ""}`}>
      <div className="page06__insight-top">
        <div className="page06__insight-left">
          <div className="page06__insight-image-container">
            <img className="page06__insight-image" src={image} alt="insight" />
          </div>
          <p className="page06__insight-title">{title}</p>
        </div>
        <div className="page06__insight-button-container">
          {expanded ? (
            <img
              src={x}
              alt="close-icon"
              className="page06__insight-close-icon"
              onClick={onToggle}
            />
          ) : (
            <button className="page06__insight-button" onClick={onToggle}>
              Learn More
            </button>
          )}
        </div>
      </div>
      <div className="page06__insight-bottom">
        <p className={`page06__insight-text ${expanded ? "visible" : ""}`}>
          {text}
        </p>
      </div>
    </div>
  );
};

export const Page06 = () => {
  const [expandedId, setExpandedId] = useState(null);

  const handleToggle = (id) =>
    setExpandedId((prevState) => (prevState === id ? null : id));

  return (
    <div className="page06__container">
      <div className="page06__title-container">
        <p className="page06__title">Insights</p>
      </div>
      <div className="page06__content">
        <div className="page06__blur01"></div>
        {insightsData.map((insight) => (
          <InsightCard
            key={insight.id}
            {...insight}
            expanded={expandedId === insight.id}
            onToggle={() => handleToggle(insight.id)}
          />
        ))}
      </div>
    </div>
  );
};
