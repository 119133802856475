import { useEffect, useState } from "react";
import "./Page03.css";

export const Page03 = ({ setIsLoading }) => {
  const [soil, setSoil] = useState("");

  // useEffect(() => {
  //   loadImage();
  // }, []);

  const loadImage = async () => {
    const imgURI = "https://mktrcdn.s3.amazonaws.com/soil.webp";
    const img = new Image();
    img.src = imgURI;
    img.crossOrigin = "anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);
      const dataUrl = canvas.toDataURL();
      setSoil(dataUrl);
      setIsLoading(false);
    };
  };

  return (
    <div
      className="page03__container"
      // style={{ backgroundImage: `url(${soil})` }}
    >
      <div className="page03__text-container">
        <p className="page03__text">Unearth More.</p>
        <p className="page03__text">Welcome to a whole new world below!</p>
      </div>
    </div>
  );
};
